<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center"
        style="color: #2b7dec"
        @click="navBack"
      >
        <v-icon color="#2B7DEC"> mdi-chevron-left </v-icon>
        Kembali
      </v-col>
    </v-row>

    <v-row class="mt-n5 mb-6">
      <v-col class="txt26-black" cols="12">
        <template v-if="verification"> Verifikasi Keanggotaan </template>
        <template v-else> Detail Keanggotaan </template>
      </v-col>
    </v-row>
    <!-- {{ verification }} -->
    <v-card class="px-6 py-6">
      <data-diri :data-diri="dataDiri" @init="initialize" />
    </v-card>

    <v-card class="px-6 py-6 mt-6">
      <template v-if="verification == 1">
        <data-verifikasi
          :data-verifikasi="dataVerification"
          @on-change-docs="onChangeDocs"
        />

        <template v-if="dataVerification.university_data.university_name">
          <v-row>
            <v-col cols="12" sm="12" class="txt26-black">
              Data Universitas
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Universitas </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              <!-- {{dataVerification}} -->
              {{ dataVerification.university_data.university_name || "-" }}
            </v-col>
          </v-row>
        </template>
      </template>
      <template v-else>
        <template v-if="mType == 1">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-0"
            mobile-breakpoint="100"
            hide-default-footer
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div
                class="action-container"
                style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #f2f2f2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                @click="toDetail(item)"
              >
                <v-icon class="action-icon"> mdi-eye </v-icon>
              </div>
            </template>
          </v-data-table>
        </template>

        <template v-if="mType == 2">
          <v-row>
            <v-col cols="12" sm="12" class="txt26-black">
              Data Universitas
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt16-gray50" cols="6" sm="4"> Universitas </v-col>
            <v-col class="txt16-gray50-bold" cols="6" sm="8">
              {{ dataPage.university_data.university_name || "-" }}
            </v-col>
          </v-row>
        </template>
      </template>
    </v-card>

    <v-card class="px-6 py-6 mt-6">
      <data-darurat :data-darurat="dataDarurat" />
    </v-card>

    <template v-if="verification">
      <v-card class="px-6 py-6 mt-6">
        <span class="txt16-black-bold"> Persetujuan </span>
        <v-row class="mt-2">
          <v-col class="txt16-gray50" cols="3"> Surat Rekomendasi </v-col>
          <v-col cols="9">
            <img
              v-if="this.dataVerification.file_payment != null"
              style="height: 500px"
              :src="this.dataVerification.file_payment.url"
            />
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col class="txt16-gray50" cols="3"> Alasan </v-col>
          <v-col cols="9">
            <v-textarea
              v-model="reason"
              placeholder="Masukkan alasan"
              rows="4"
              color="#38BA98"
              outlined
              no-resize
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="#2E976C"
              class="text-capitalize mx-2"
              style="color: white"
              @click="
                submitType = 1;
                modal = true;
              "
            >
              Verifikasi
            </v-btn>
            <v-btn
              color="#EA3E3A"
              class="text-capitalize"
              style="color: white"
              @click="
                submitType = 2;
                modal = true;
              "
            >
              Belum Sesuai
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </template>

    <modal-detail
      :show="dialog"
      :data-modal="dataDetail"
      @close="closeDialog"
    />

    <modal-konfirmasi :show="modal" @submit="confirm" />
  </v-container>
</template>

<script>
import DataDiri from "./DataDiri.vue";
import DataDarurat from "./DataDarurat.vue";
import DataVerifikasi from "./DataVerifikasi.vue";
import ModalDetail from "./ModalDetail.vue";
import ModalKonfirmasi from "../komponen/modal/ModalKonfirmasi.vue";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    DataDiri,
    DataDarurat,
    DataVerifikasi,
    ModalDetail,
    ModalKonfirmasi,
  },

  data: () => ({
    modal: false,
    dialog: false,
    dataPage: null,
    dataDiri: null,
    dataDarurat: null,
    dataDetail: null,
    mType: 1,
    verification: false,
    dataVerification: {},
    reason: "",
    headers: [
      { text: "Perusahaan", align: "start", value: "name" },
      { text: "Bidang", value: "sector", sortable: false },
      { text: "Domisili", value: "city", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Website", value: "website", sortable: false },
      { text: "", value: "actions" },
    ],
    dataTable: [],
    submitType: 2,
    docs: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.mType = this.$route.params.type;
    this.verification = parseInt(this.$route.params.verification);
    if (this.verification === 1) {
      this.getDataVerification();
    } else {
      this.initialize();
    }
  },

  methods: {
    navBack() {
      this.$router.push({ name: "Keanggotaan" });
    },

    onChangeDocs(p) {
      this.docs = p;
    },

    toDetail(p) {
      // console.log(p)
      this.getDetail(p.mc_hash);
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    confirm(p) {
      // console.log(p)
      if (p === 0) {
        this.modal = false;
      } else {
        this.modal = false;
        this.submit();
      }
    },

    initialize() {
      // Check user menus
      // const userMenus = localStorage.getItem('userMenus').split(',')

      // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
      //   this.isAllowVerification = true
      // }

      const requestBody = {
        m_hash: this.$route.params.id,
      };

      axios
        .post("/v1/admin/member/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            this.dataPage = res.data.data;
            this.dataDiri = res.data.data.personal_data;
            this.dataDarurat = res.data.data.emergency_data;
            this.dataTable = res.data.data.company_data.list_company;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getDetail(p) {
      const requestBody = {
        mc_hash: p,
      };

      axios
        .post("/v1/admin/company/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            this.dataDetail = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getDataVerification() {
      const requestBody = {
        m_hash: this.$route.params.id,
      };

      axios
        .post("/v1/admin/member/detail/verify", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dataDiri = res.data.data.personal_data;
            this.dataDarurat = res.data.data.emergency_data;
            this.dataVerification = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    submit() {
      const requestBody = {
        m_hash: this.$route.params.id,
        status: this.submitType,
        reason: this.reason,
      };

      axios
        .post("/v1/admin/member/verify", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message);
            this.$router.push({ name: "Keanggotaan" });
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt16-black-bold {
  @extend .p-1;
  color: #333333;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
