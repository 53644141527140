<template>
  <div v-if="dataDiri !== null">
    <v-row>
      <v-col cols="12" sm="12" class="txt26-black"> Data Diri </v-col>
      <v-col cols="12" sm="4">
        <template v-if="dataDiri.photo">
          <v-img :src="dataDiri.photo.url" alt="profileimage" />
        </template>
        <template v-else>
          <v-icon size="100"> mdi-account-circle </v-icon>
        </template>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col class="d-flex txt16-gray50"> Nomer KTA </v-col>
          <v-col class="d-flex txt16-gray50-bold">
            {{ dataDiri.no_kta || "-" }}
            <div
              v-if="isAllowModify"
              class="icon-container ml-2 pl-1"
              @click="dialog = true"
            >
              <v-icon color="#FFC709" size="18"> mdi-pencil </v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> NIK </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.nik || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Nama Lengkap </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.name || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Tempat, Tanggal lahir </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.place_date_birth || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Agama </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.belief || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Pendidikan Terakhir </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.education || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Alamat </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.address || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Nomor Telepon </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.phone || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Email </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.email || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> BPD </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.bpd || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> BPC </v-col>
          <v-col class="txt16-gray50-bold">
            {{ dataDiri.bpc || "-" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Organisasi Lain </v-col>
          <v-col class="d-flex flex-column">
            <template v-if="dataDiri.other_org.length > 0">
              <div
                v-for="(v, i) in dataDiri.other_org"
                :key="'organisasi-' + i"
                class="txt16-gray50-bold mb-2"
              >
                {{ i + 1 }} . {{ v }}
              </div>
            </template>
            <template v-else>
              <div class="txt16-gray50-bold">-</div>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="txt16-gray50"> Jabatan </v-col>
          <v-col class="d-flex flex-column">
            <template v-if="dataDiri.jabatan.length > 0">
              <div
                v-for="(v, i) in dataDiri.jabatan"
                :key="'organisasi-' + i"
                class="txt16-gray50-bold mb-2"
              >
                {{ i + 1 }} . {{ v }}
              </div>
            </template>
            <template v-else>
              <div class="txt16-gray50-bold">-</div>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="400">
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold"> Ubah Nomor KTA </span>
            <span style="cursor: pointer" @click="dialog = false"> X </span>
          </v-col>
        </v-row>
        <template v-if="dataDiri !== null">
          <v-row>
            <v-col class="txt16-gray50">
              <v-text-field
                v-model="noKTA"
                placeholder="Masukkan nomor KTA"
                color="#2E976C"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <div class="d-flex justify-end mt-4">
            <v-btn
              color="#2E976C"
              class="mx-2 text-capitalize"
              style="font-weight: bold; color: white"
              @click="save"
            >
              Simpan
            </v-btn>
            <v-btn
              color="#CCCCCC"
              class="mx-2 text-capitalize"
              style="font-weight: bold; color: white"
              @click="dialog = false"
            >
              Batal
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  props: {
    dataDiri: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    dialog: false,
    isAllowModify: false,
    noKTA: "",
  }),

  computed: {},

  watch: {},

  created() {
    const userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const userMenus = userInfo.menu_function;
    if (userMenus.includes("MEMBER_MOD")) this.isAllowModify = true;
  },

  methods: {
    save() {
      const requestBody = {
        m_hash: this.$route.params.id,
        no_kta: this.noKTA,
      };

      axios
        .post("/v1/admin/member/update", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            this.dialog = false;
            this.$toast.success(res.data.message);
            this.$emit("init");
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.icon-container {
  width: 28px;
  height: 28px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
</style>
