<template>
  <div v-if="dataVerifikasi !== null">
    <!-- {{ dataVerifikasi }} -->
    <v-row>
      <v-col cols="12" sm="12" class="txt26-black"> Data Perusahaan </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Nama Badan Usaha </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.name || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Bentuk Badan Usaha </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.type || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Bidang Usaha </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.sector || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Kode KBLI </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.kbli_code || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> NPWP </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.npwp || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Tanggal Berdiri </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.date || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Alamat </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.address || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Nomor Telepon </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.phone || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Email </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.email || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Website </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.website || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Jumlah Karyawan </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        {{ dataVerifikasi.company_data.employee_count || "-" }}
      </v-col>
    </v-row>
    <v-row>
      <v-col class="txt16-gray50" cols="6" sm="4"> Asosiasi Lain </v-col>
      <v-col class="txt16-gray50-bold" cols="6" sm="8">
        <div
          v-for="(v, i) in dataVerifikasi.company_data.company_other"
          :key="i"
          class="mb-3"
        >
          {{ i + 1 }} {{ v }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row
          v-for="(v, i) in dataVerifikasi.company_data.list_doc"
          :key="i"
          class="d-flex align-center"
        >
          <v-col class="txt16-gray50">
            {{ v.name || "-" }}
          </v-col>
          <v-col
            class="txt16-gray50-bold"
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ v.file_name || "-" }}
            <span class="ml-1">
              <v-icon
                size="18px"
                style="cursor: pointer"
                @click="previewURL(v)"
              >
                mdi-eye
              </v-icon>
            </span>
          </v-col>
          <!-- <v-col>
            <v-radio-group
              v-model="docValue[i]"
              row
            >
              <v-radio
                color="#38BA98"
                label="Terima"
                :value="1"
              />
              <v-radio
                color="#EA3E3A"
                label="Tidak"
                :value="0"
              />
            </v-radio-group>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    dataVerifikasi: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    docValue: [0, 0, 0, 0, 0, 0],
  }),

  computed: {},

  watch: {
    docValue: {
      immediate: true,
      handler(v) {
        this.$emit("on-change-docs", v);
      },
    },
  },

  created() {},

  methods: {
    previewURL(p) {
      const url = [];
      url.push(p.url);
      this.$viewerApi({ images: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
