<template>
  <div v-if="dataDarurat !== null">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="txt26-black"
      >
        Data Darurat
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="txt16-gray50"
        cols="6"
        sm="4"
      >
        Nama Lengkap
      </v-col>
      <v-col
        class="txt16-gray50-bold"
        cols="6"
        sm="8"
      >
        {{ dataDarurat.name || '-' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="txt16-gray50"
        cols="6"
        sm="4"
      >
        Nomer Telepon
      </v-col>
      <v-col
        class="txt16-gray50-bold"
        cols="6"
        sm="8"
      >
        {{ dataDarurat.phone || '-' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="txt16-gray50"
        cols="6"
        sm="4"
      >
        Hubungan
      </v-col>
      <v-col
        class="txt16-gray50-bold"
        cols="6"
        sm="8"
      >
        {{ dataDarurat.connection || '-' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="txt16-gray50"
        cols="6"
        sm="4"
      >
        Alamat
      </v-col>
      <v-col
        class="txt16-gray50-bold"
        cols="6"
        sm="8"
      >
        {{ dataDarurat.address || '-' }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: {
      dataDarurat: {
        type: Object,
        default: null,
      },
    },

    data: () => ({}),

    computed: {},

    watch: {},

    created () {},

    methods: {},
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
